/* eslint-disable react/destructuring-assignment */
import { Button } from 'antd'
import cn from 'classname'
import PropTypes from 'prop-types'

const ButtonV2 = ({ children, variant, onClick, className, ...props }) => (
  <Button
    className={cn(
      'font-sfui w-max px-4 py-3 h-12 rounded-lg font-semibold',
      {
        'border-none bg-g400 text-white': variant === 'contained',
        'border border-n75 text-n800': variant === 'outlined',
      },
      className,
    )}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
)

export default ButtonV2

ButtonV2.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined']),
  className: PropTypes.string,
  onClick: PropTypes.func,
}

ButtonV2.defaultProps = {
  variant: 'contained',
  className: '',
  onClick: undefined,
}
