import CaseStudySection from 'components/CaseStudy'
import FooterV2 from 'components/FooterV2/FooterV2'
import HeaderV2 from 'components/HeaderV2/HeaderV2'
import SignInOrDemo from 'components/SignInOrDemo/SignInOrDemo'
import { useAuth } from 'components/auth'
import Hero from './components/Hero'
import PartnerSection from './components/PartnerSection'
import PricingSection from './components/PricingSection'
import TrialSection from './components/TrialSection'
import WorkSection from './components/WorkSection'

const LandingPageComponent = ({ locale, translations }) => {
  const { signInWithGoogleAccount, createDemoAccount } = useAuth()
  return (
    <div className="pt-[72px] md:pt-20 selection:bg-g400">
      <HeaderV2 locale={locale} translations={translations} />
      <Hero translations={translations} handleDemoClick={createDemoAccount} />
      <PartnerSection translations={translations} />
      <WorkSection translations={translations} />
      <CaseStudySection translations={translations} />
      <TrialSection translations={translations} />
      <PricingSection translations={translations} />
      <TrialSection translations={translations} />
      <SignInOrDemo
        translations={translations}
        handleSignIn={signInWithGoogleAccount}
        handleDemoClick={createDemoAccount}
      />
      <FooterV2 translations={translations} />
    </div>
  )
}

export default LandingPageComponent
