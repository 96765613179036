import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'
import { ApiCall } from 'services'

const CollectEmailForDemo = ({ translations, handleDemoClick }) => {
  const [showEmailForm, setShowEmailForm] = useState(false)

  return (
    <>
      {showEmailForm && (
        <Form
          onFinish={(values) => {
            ApiCall.WaitList.demoSignUp(values)
              .then(() => {
                handleDemoClick()
              })
              .catch((error) => {
                message.error('An error occurred. Please try again.')
              })
          }}
        >
          <Form.Item
            name="email"
            label={translations('email')}
            rules={[
              {
                required: true,
                message: translations('email_required'),
              },
              {
                type: 'email',
                message: translations('email_invalid'),
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Button className="bg-g400 text-white" htmlType="submit">
            {translations('start_demo')}
          </Button>
        </Form>
      )}
      {!showEmailForm && (
        <Button
          className=" bg-g400 text-white"
          onClick={() => setShowEmailForm(true)}
        >
          {translations('demo_or_signup_cta')}
        </Button>
      )}
    </>
  )
}

export default CollectEmailForDemo
