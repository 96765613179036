import LandingPage from 'views/LandingPage/LandingPage'

import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const LandingPageLocalized = ({ locale }) => {
  const { t } = useTranslation('common')

  return <LandingPage translations={t} locale={locale} />
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default LandingPageLocalized
