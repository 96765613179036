export const LANDINGPAGE = {
  title:
    'Increase WBSO Claims by 60% - Automate WBSO Time Tracking with Traqqie',
  description:
    'Eliminate the chase for engineer timesheets and streamline your WBSO submissions with Traqqie. Our automated platform boosts your claims by 60%, ensures compliance, and saves significant time. Discover how Traqqie can transform your subsidy management and let you focus on innovation.',
}

export const generateAlternatesAndCanonical = (locale, route = undefined) => {
  const DEFAULT_LANGUAGE = 'en'
  const supportedLanguages = [
    { lang: 'en', path: '/en' },
    { lang: 'nl', path: '/nl' },
    { lang: 'x-default', path: '' },
    // Add more languages as needed
  ]

  // Assuming your site's base URL is example.com
  const baseUrl = 'https://traqqie.com'

  // Generate the language alternates array
  return {
    alternates: supportedLanguages.map(({ lang, path }) => ({
      hrefLang: lang,
      href:
        route === undefined
          ? `${baseUrl}${path}/`
          : `${baseUrl}${path}/${route}/`, // Adjust the path as necessary
    })),
    canonoical:
      locale !== DEFAULT_LANGUAGE
        ? `${baseUrl}/${locale}/${route === undefined ? '' : `${route}/`}`
        : `${baseUrl}/${route === undefined ? '' : `${route}/`}`,
  }
}
