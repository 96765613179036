import cn from 'classname'
import ButtonV2 from 'components/ButtonV2/ButtonV2'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { PAGE } from 'utils/pages'

const Hero = ({ translations }) => {
  const router = useRouter()

  const fullTitle = translations('hero_title')

  const [displayedTitle, setDisplayedTitle] = useState(fullTitle) // Default to full title for SSR
  const indexRef = useRef(0)

  useEffect(() => {
    // Check if window object is available to determine if it's running on the client
    if (typeof window !== 'undefined') {
      // Initialize state for client-side
      setDisplayedTitle('')
      indexRef.current = 0

      const typeTitle = () => {
        setDisplayedTitle(fullTitle.slice(0, indexRef.current + 1))
        indexRef.current++

        // Continue typing if there are characters left
        if (indexRef.current < fullTitle.length) {
          let delay
          if (fullTitle.charAt(indexRef.current) === '-') {
            delay = 300 // Longer pause at the hyphen
          } else if (indexRef.current <= fullTitle.indexOf('-')) {
            delay = 80 // Quicker initial typing up to the hyphen
          } else {
            delay = 50 // Quicker than default speed for the rest
          }
          setTimeout(typeTitle, delay)
        }
      }

      // Start typing with a slight delay
      const initialTimeout = setTimeout(typeTitle, 100)

      // Cleanup timeout if the component unmounts
      return () => clearTimeout(initialTimeout)
    }
  }, [fullTitle])

  return (
    <section
      id="hero"
      className={cn(
        'w-full min-h-max bg-g400 text-center flex flex-col items-center pt-20 px-6',
        'lg:px-[100px]',
      )}
    >
      <h1
        className={cn(
          'font-inter font-semibold text-white text-3xl max-w-xs mb-0',
          'md:text-5xl md:leading-[60px] md:max-w-3xl',
        )}
      >
        {displayedTitle}
      </h1>
      <p
        className={cn(
          'text-white font-inter text-base mt-4',
          'md:text-xl md:mt-5',
        )}
      >
        {translations('hero_subtitle')}
      </p>

      <div className="flex flex-row justify-center space-x-4 mt-4 md:mt-8">
        <ButtonV2 variant="outlined" onClick={() => router.push(PAGE.signin)}>
          Get Started
        </ButtonV2>
        <ButtonV2
          variant="outlined"
          className="animate-pulse-animation"
          onClick={() => router.push(PAGE.signin)}
        >
          Free demo
        </ButtonV2>
      </div>

      <img
        width={1136}
        height={503}
        src="/images/calendar.png"
        className="w-full h-auto object-cover mt-12 max-w-[1136px] md:mt-16"
      />
    </section>
  )
}

export default Hero
