import cn from 'classname'

const WorkSection = ({ translations }) => {
  const workTexts = translations('work_texts', { returnObjects: true })

  return (
    <section
      id="how-it-works"
      className="w-full flex flex-col items-center py-24 px-6 gap-y-16 bg-[#F9FAFB] lg:px-[100px]"
    >
      <div className="flex flex-col items-center gap-y-2">
        <h2 className="mb-0 font-semibold font-inter text-gr900 text-center text-3xl md:text-4xl">
          {translations('work_title')}
        </h2>
        <p className="font-inter text-gr600 text-center mt-2 text-lg md:text-lg">
          {translations('work_subtitle')}
        </p>
      </div>
      <ul
        className={cn(
          'w-full flex flex-col gap-y-8 items-center',
          'md:flex-row md:gap-x-8 md:flex-wrap md:items-start md:justify-center',
        )}
      >
        {workTexts.map(({ title, icon, desc }) => {
          return (
            <li
              key={title}
              className="flex flex-col items-center text-center max-w-xs md:w-72"
            >
              <img
                key={title}
                alt={title}
                src={icon}
                width={36}
                height={36}
                className="w-9 h-9"
              />
              <h3 className="font-inter mb-0 font-semibold text-xl text-gr900 mt-5">
                {title}
              </h3>
              <p className="font-inter text-gr600 mt-2">{desc}</p>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default WorkSection
