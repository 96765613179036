import { MenuOutlined } from '@ant-design/icons'
import { Divider, Drawer } from 'antd'
import cn from 'classname'
import ButtonV2 from 'components/ButtonV2/ButtonV2'
import { useAuth } from 'components/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { PAGE } from 'utils/pages'

const HeaderV2 = ({ locale, translations }) => {
  const router = useRouter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { createDemoAccount } = useAuth()

  const handleLoginClick = () => {
    router.push(PAGE.signin)
  }

  const HEADER_MENU = [
    { text: translations('nav_home'), link: PAGE.landing },
    { text: translations('nav_wbso_application'), link: PAGE.wbso_application },
    {
      text: translations('nav_how_it_works'),
      link: `${PAGE.landing}#how-it-works`,
    },
    {
      text: 'Case Study',
      link: `${PAGE.landing}#case-study`,
    },
    // { text: translations('nav_demo'), link: `${PAGE.landing}#demo` },
    { text: translations('nav_pricing'), link: `${PAGE.landing}#pricing` },
    { text: translations('nav_blog'), link: PAGE.blog },
    { text: translations('nav_wbso_calculator'), link: PAGE.wbsoCalculator },
    {
      text: translations('nav_wbso_knowledge_base'),
      link: locale === 'en' ? '/en/wbso-knowledge-base' : '/nl/wbso-kennisbank',
    },
  ]

  return (
    <header
      className={cn(
        'w-full z-10 flex items-center justify-between px-6 py-4 border-b border-[#F2F3F7] fixed top-0 bg-white shadow',
        'lg:px-[100px]',
      )}
    >
      <div className="flex items-center gap-x-10 lg:gap-x-16">
        <span className="font-sfui block h-10 text-2xl text-g400 font-extrabold md:text-[32px]">
          <a href="/" style={{ color: 'black' }}>
            Traqqie
          </a>
        </span>
        <ul className="w-full hidden items-center gap-x-8 mb-0 md:flex">
          {HEADER_MENU.map(({ text, link }) => {
            return (
              <li key={text}>
                <Link key={link} href={link} passHref>
                  <a
                    className={cn(
                      'text-sm font-medium text-black font-sfui leading-none hover:text-g400',
                    )}
                  >
                    {text}
                  </a>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="items-center justify-end hidden gap-x-2 md:flex">
        <ButtonV2 variant="outlined" onClick={handleLoginClick}>
          Login
        </ButtonV2>
        <ButtonV2 onClick={handleLoginClick}>Free Demo</ButtonV2>
      </div>

      <button
        className="bg-transparent px-3 md:hidden"
        onClick={() => setIsMenuOpen(true)}
      >
        <MenuOutlined size={24} className="text-[#475467]" />
      </button>
      <Drawer open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <ul className="w-full flex flex-col items-center gap-y-6">
          {HEADER_MENU.map(({ text, link }) => {
            return (
              <li key={link}>
                <Link
                  key={link}
                  href={link}
                  className={cn('text-sm font-medium text-black font-sfui')}
                >
                  {text}
                </Link>
              </li>
            )
          })}
        </ul>
        <Divider />
        <div className="w-full flex flex-col items-center gap-y-3 mt-3">
          <ButtonV2 className="!w-36" onClick={handleLoginClick}>
            Free Demo
          </ButtonV2>
          <ButtonV2
            variant="outlined"
            className="!w-36"
            onClick={handleLoginClick}
          >
            Login
          </ButtonV2>
        </div>
      </Drawer>
    </header>
  )
}

export default HeaderV2
