import { useState } from 'react'

import cn from 'classname'
import ButtonV2 from 'components/ButtonV2/ButtonV2'
import { useRouter } from 'next/router'

const PricingSection = ({ translations }) => {
  const router = useRouter()
  const [pricingPeriod, setPricingPeriod] = useState('annual')

  const PRICING_PERIODS = [
    { text: translations('common:pricing_periods.monthly'), value: 'monthly' },
    { text: translations('common:pricing_periods.annual'), value: 'annual' },
  ]

  const PRICING_FREQUENCIES = [
    {
      text: translations('common:pricing_billing_frequency_monthly'),
      value: 'monthly',
    },
    {
      text: translations('common:pricing_billing_frequency_yearly'),
      value: 'annual',
    },
  ]

  const PRICING_OPT = translations('pricing_options', { returnObjects: true })[
    pricingPeriod
  ]

  return (
    <section
      id="pricing"
      className="w-full flex flex-col items-center py-24 px-6 lg:px-[100px]"
    >
      <div className="flex flex-col items-center gap-y-6">
        <h2 className="mb-0 font-semibold font-inter text-gr900 text-center text-3xl md:text-5xl">
          {translations('pricing_title')}
        </h2>
        <p className="font-inter m-0 text-gr600 text-center text-lg md:text-xl">
          {translations('pricing_subtitle')}
        </p>
      </div>

      <div className="mt-10 flex items-center">
        {PRICING_PERIODS.map(({ text, value }) => {
          return (
            <button
              key={value}
              className={cn(
                'font-inter font-semibold bg-white px-[14px] py-[10px] rounded-md',
                {
                  'text-gr700 shadow': pricingPeriod === value,
                  'text-gr500': pricingPeriod !== value,
                },
              )}
              onClick={() => setPricingPeriod(value)}
            >
              {text}
            </button>
          )
        })}
      </div>

      <div
        className={cn(
          'mt-12 flex flex-col items-center gap-y-8 font-inter',
          'md:mt-20 md:flex-row md:gap-x-8',
        )}
      >
        {PRICING_OPT.map((d) => {
          return (
            <div
              key={d.title}
              className={cn(
                'relative flex flex-col overflow-hidden items-center p-6 pt-8 bg-white border border-solid border-gr200 rounded-[18px] shadow-lg max-w-md',
                'md:max-w-xl md:p-8 md:pt-10',
              )}
            >
              {d.isPopular && (
                <span
                  style={{ transform: 'rotateZ(45deg) !important' }}
                  className="absolute origin-top-left -right-20 -top-8 block px-10 py-1 bg-g400 text-sm text-white"
                >
                  {translations('pricing_popular_text')}
                </span>
              )}
              <div className="flex flex-col items-center text-center text-gr900">
                <span className="text-4xl font-semibold md:text-5xl">
                  {d.price}
                </span>
                <h3 className="mb-0 mt-4 font-semibold text-xl">{d.title}</h3>
                <p className="mb-0 text-gr600 mt-1">{d.subtitle}</p>
              </div>
              <div className="flex flex-col gap-y-3 text-gr600 mt-8">
                {d.benefits.map(({ text, isGiven }) => {
                  return (
                    <div key={text} className="flex gap-x-1.5">
                      <img
                        alt={text}
                        src={isGiven ? '/icons/check.svg' : '/icons/close.svg'}
                        width={24}
                        height={24}
                        className="w-6 h-6"
                      />
                      <p className="mb-0">{text}</p>
                    </div>
                  )
                })}
              </div>
              <ButtonV2
                className="!w-full mt-8"
                onClick={() => router.push(PAGE.signin)}
              >
                Get started
              </ButtonV2>

              <p style={{ fontSize: '12px', marginTop: '5px' }}>
                {
                  PRICING_FREQUENCIES.find((f) => f.value === pricingPeriod)
                    .text
                }
              </p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default PricingSection
