import ButtonV2 from 'components/ButtonV2/ButtonV2'
import { useRouter } from 'next/router'
import { PAGE } from 'utils/pages'

const TrialSection = ({ translations }) => {
  const router = useRouter()

  return (
    <section
      id="#trial"
      className="w-full flex flex-col items-center py-24 px-6 gap-y-16 bg-[#F9FAFB] lg:px-[100px]"
    >
      <div className="w-full flex flex-col items-center max-w-[1216px] md:flex-row md:items-start md:justify-between">
        <div className="flex flex-col items-center text-center gap-y-4 md:items-start">
          <span className="font-semibold text-4xl text-gr900">
            {translations('trial_title')}
          </span>
          <span className="text-gray-600">
            {translations('trial_subtitle')}
          </span>
        </div>

        <ButtonV2
          className="mt-12 md:mt-0"
          onClick={() => router.push(PAGE.signin)}
        >
          Free Demo
        </ButtonV2>
      </div>
    </section>
  )
}

export default TrialSection
