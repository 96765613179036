import LandingPageComponent from 'components/LandingPage/LandingPage'
import { generateAlternatesAndCanonical } from 'data/seo/seo'
import { NextSeo } from 'next-seo'

const LandingPage = ({ translations, locale }) => {
  return (
    <>
      <NextSeo
        title={translations('meta_title')}
        description={translations('meta_description')}
        languageAlternates={generateAlternatesAndCanonical(locale).alternates}
        canonical={generateAlternatesAndCanonical(locale).canonoical}
        // canonical=""
      />
      <LandingPageComponent locale={locale} translations={translations} />
    </>
  )
}

export default LandingPage
